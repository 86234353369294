<template>
  <div class="container">
    <div class="accordion" id="accordionCopys">
      <div class="card">
        <div class="card-header">
          <b>{{controlName}}</b>
          <h2 class="mb-0">
            <button @click="btnGuardarCopy" v-if="agregar" class="btn btn-success float-right "><font-awesome-icon icon="save" /></button>
            <button :class="{'btn btn-primary float-right': !agregar,'btn btn-danger float-right mx-3': agregar}" @click="btnAgregar" type="button" data-toggle="collapse" data-target="#collapseCopy" aria-expanded="true" aria-controls="collapseCopy"><font-awesome-icon v-if="agregar" icon="times" /> <font-awesome-icon v-else icon="plus" /></button>
          </h2>
        </div>
        <div id="collapseCopy" class="collapse" data-parent="#accordionCopys">
          <div class="card-body">
            <div class="container">
              <div class="row">
                <div class="col-md-4"><label for="nombre">Id Control:</label></div>
                <div class="col-md-8"><input disabled type="number" class="form-control" id="controlid" v-model="item_copy.controlid"/></div>
              </div>
              <div class="row">
                <div class="col-md-4"><label for="copy">Copy:</label></div>
                <div class="col-md-8"><textarea class="form-control" rows="4" id="copy" v-model="item_copy.copy"></textarea></div>
              </div>
              <!-- <div class="row">
                <div class="col-md-4"><label for="status">Estatus:</label></div>    
                <div class="col-md-8"><select class="custom-select" id="status" v-model="item_copy.status"><option selected :value="0" >Inactivo</option><option :value="1">Activo</option></select></div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <DataTable :value="copys" editMode="row" data-key="id" :editingRows.sync="editingRowsCopys" @row-edit-init="onRowEditInitCopys" @row-edit-cancel="onRowEditCancelCopys" @row-edit-save="onRowEditCompleteCopys">
      <template #empty>No se encontraron registros</template>
      <!-- <Column field="controlid" header="Id Control" headerStyle="width: 10%">
        <template #editor="slotProps">
          <input disabled class="form-control" v-model="slotProps.data[slotProps.column.field]" />
        </template>
      </Column> -->
      <Column field="copy" header="Copy" headerStyle="width: 40%"><template #editor="slotProps"><textarea class="form-control mb-3" rows="3" v-model="slotProps.data[slotProps.column.field]"/></template></Column>
      <Column field="status" header="Estatus" headerStyle="width: 20%" bodyStyle="text-align:center"><template #body="slotProps"><div v-if="slotProps.data.status==1" class="rounded bg-success text-white font-weight-bold p-2 text-center"> Activo <font-awesome-icon spin icon="cog" /></div> <div v-else class="rounded bg-info text-white font-weight-bold p-2 text-center"> Desactivado <font-awesome-icon icon="cog" /></div></template><template #editor="slotProps"><div v-if="slotProps.data.status==1" class="rounded bg-success text-white font-weight-bold p-2 text-center"> Activo <font-awesome-icon spin icon="cog" /></div><div v-else class="rounded bg-info text-white font-weight-bold p-2 text-center">Desactivado<font-awesome-icon icon="cog" /></div></template></Column>
      <Column  header="Activar/Desactivar" headerStyle="width: 15%" bodyStyle="text-align:center"><template #body="slotProps"><button @click="cambiarEstatusCopy(slotProps.data.id,slotProps.data.status,slotProps.index);" class="btn btn-sm btn-space " :class="{'btn-info':slotProps.data.status==0, 'btn-secondary':slotProps.data.status==1}"><font-awesome-icon v-if="slotProps.data.status==1" icon="pause" /><font-awesome-icon v-else icon="play" /><span v-if="slotProps.data.status==1">  Desactivar</span><span v-else>  Activar</span></button></template><template #editor="slotProps"><button @click="cambiarEstatusCopy(slotProps.data.controlid,slotProps.data.status,slotProps.index);" class="btn btn-sm btn-space " :class="{'btn-info':slotProps.data.status==0, 'btn-secondary':slotProps.data.status==1}"><font-awesome-icon v-if="slotProps.data.status==1" icon="pause" /><font-awesome-icon v-else icon="play" /><span v-if="slotProps.data.status==1">  Desactivar</span><span v-else>  Activar</span></button></template></Column>
      <Column :rowEditor="true" bodyStyle="text-align:center" headerStyle="width: 15%"></Column>
    </DataTable>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import controlesServices from "../../../services/controles";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
// import Dropdown from "primevue/dropdown";
import {faPlus,faTimes,faSave} from "@fortawesome/free-solid-svg-icons";
library.add(faPlus, faTimes, faSave);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue from "vue";
// import Toast from 'primevue/toast';

export default {
  name: "Copys",
  data() {
    return {
      agregar: false,
      copys: [],
      editingCellRows: {},
      editingRowsCopys: [],
      item_copy: { controlid: null, copy: null },
    };
  },
  components: {
    DataTable,
    Column,
    FontAwesomeIcon,

  },
  props: {
    controlId: null,
    controlName:null
  },
  originalRows: null,
  created() {
    this.originalRows = {};
  },
  mounted() {
    this.item_copy = {};
    this.item_copy.controlid = this.controlId;
    controlesServices.getCopys(this.controlId).then((resp) => {
      this.copys = resp;
    });
    this.agregar = false;
  },
  methods: {
        showSuccess() {
            this.$toast.add({severity:'success', summary: 'Operación completada', detail:'la acción se ha completado correctamente', life: 3000});
        },
        showError() {
            this.$toast.add({severity:'error', summary: 'Error', detail:'Ha ocurrido un error durante el proceso', life: 3000});
        },
    onRowEditCompleteCopys(event) {
      let datos={copyId:event.data.id,copy:event.data.copy};
      controlesServices.updateCopy(datos).then(res=>{
        if(res!=null){
        console.log(res);
        this.showSuccess();
        }
        else{
          this.showError();
        }
      })
    },
    onCellEdit(newValue, props) {       
      if (!this.editingCellRows[props.index]) {
        this.editingCellRows[props.index] = { ...props.data };
      }
      this.editingCellRows[props.index][props.column.field] = newValue;
    },
    onRowEditInitCopys(event) {
      this.originalRows[event.index] = { ...this.copys[event.index] };
    },
    onRowEditCancelCopys(event) {
      Vue.set(this.copys, event.index, this.originalRows[event.index]);
    },
    btnAgregar() {
      this.agregar = !this.agregar;
    },
    btnGuardarCopy() {
      controlesServices.AgregarCopy(this.item_copy).then((res) => {
        if(res!=null){
          this.copys.push(res);
          this.item_copy.copy = null;
          this.showSuccess();
        }
        else{
          this.showError();
        }
      });
    },
    cambiarEstatusCopy(id,estatus,index){
        controlesServices.CambiarEstatusCopy(id, estatus).then(res=>{
          if(res.status==200){
              this.copys[index].status=!this.copys[index].status;
              this.showSuccess();
          }
        });
    }
  },
};
</script>