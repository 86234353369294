<template>
  <div class="container">
    <Toast />
    <div class="input-group">
      <h3 class="my-2">Controles</h3>
      <div class="switch-network row" style="padding-left:2em">
        <div class="btn-network rounded-circle" :class="{'bg-whatsapp': plataforma==0}" @click="seleccionarWA"><i class="icon-whatsapp opt-wa"></i></div>
        <div class="btn-network rounded-circle" :class="{'bg-messenger': plataforma==1}" @click="seleccionarFB"><i class="icon-messenger-new opt-fb"></i></div>
      </div>
    </div>
    <button class="btn btn-primary float-right mb-2" @click="openModalControl" type="button"><font-awesome-icon icon="plus" />Agregar</button>
    <table class="table table-hover table-reflow table-bordered">
      <thead class="thead-light">
        <tr>
          <!-- <th>Id</th> -->
          <th>Nombre</th>
          <th>Descripción</th>
          <th width="15%">Estatus</th>
          <th width="40%">Controles</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(control,index) in controles" :key="control.id">
          <!-- <th scope="row">{{control.id}}</th> -->
          <th>{{control.nombre}}</th>
          <th>{{control.descripcion}}</th>
          <td>
            <div v-if="control.status==1" class="rounded bg-success text-white font-weight-bold p-2 text-center">  Funcionando <font-awesome-icon spin icon="cog" /></div>
            <div v-else class="rounded bg-info text-white font-weight-bold p-2 text-center">  Detenido <font-awesome-icon icon="cog" /></div>
          </td>
          <td>
            <button @click="cambiarEstatus(control.id,control.status,index);" class="btn btn-sm btn-space" :class="{'btn-info':control.status==0, 'btn-secondary':control.status==1}"><font-awesome-icon v-if="control.status==1" icon="pause" /><font-awesome-icon v-else icon="play" /><span v-if="control.status==1">  Detener</span><span v-else>  Iniciar</span></button>
            <button class="btn btn-primary btn-sm btn-space" label="Show" icon="pi pi-external-link" @click="openModalControl(control,index)"><font-awesome-icon icon="edit" />  Control</button>
            <button class="btn btn-primary btn-sm btn-space" label="Show" icon="pi pi-external-link" @click="openModalCopys(control.id,control.nombre)"><font-awesome-icon icon="copy" />  Copys</button>
            <button class="btn btn-primary btn-sm btn-space" label="Show" icon="pi pi-external-link" @click="openModalParametros(control.id,control.nombre)"><font-awesome-icon icon="cogs" />  Parámetros</button>
          </td>
        </tr>
      </tbody>
    </table>
    <Dialog header="Copys" :visible.sync="displayModalCopys" :style="{width: '60vw'}" :modal="true">
      <Copys :controlId="controlId" :controlName="controlName"></Copys>
      <template #footer>
        <button class="btn btn-secondary" @click="closeModalCopys">Salir</button>
      </template>
    </Dialog>

    <Dialog header="Parámetros" :visible.sync="displayModalParametros" :style="{width: '60vw'}" :modal="true">
      <Parametros :controlId="controlId" :controlName="controlName"></Parametros>
      <template #footer>
        <button class="btn btn-secondary" label="No" @click="closeModalParametros">Salir</button>
      </template>
    </Dialog>

    <Dialog header="Control" :visible.sync="displayModalControl" :style="{width: '40vw'}" :modal="true">
      <div class="container">
        <div class="row" v-show="item_control.id!=7 && item_control.id!=1 && item_control.id!=53">
          <div class="col-12 col-md-2"> <label for="nombreControl">Nombre</label></div>
          <div class="col-12 col-md-10"> <input type="text" class="form-control" id="nombreControl" v-model="item_control.nombre"/></div>
        </div>
        <div class="row" v-show="item_control.id!=7 && item_control.id!=1 && item_control.id!=53">
          <div class="col-12 col-md-2"><label for="descripcionControl">Descripción:</label></div>
          <div class="col-12 col-md-10"><textarea class="form-control" rows="4" id="descripcionControl" v-model="item_control.descripcion"/></div>
        </div>
        <div class="row" v-if="item_control.id==7">
          <div class="col-12 col-md-6"> <label for="nombreControl">Activar cuando la cola esté en:</label></div>
          <div class="col-12 col-md-6 mb-2"> <input type="number" class="form-control" id="nombreControl" placeholder="Cola baja" v-model="colaPyp.min"/></div>

          <div class="col-12 col-md-6"> <label for="nombreControl">Desactivar cuando esté en:</label></div>
          <div class="col-12 col-md-6"> <input type="number" class="form-control" id="nombreControl" placeholder="Cola alta" v-model="colaPyp.max"/></div>
        </div>

        <div class="row" v-if="item_control.id==1"> 
          <div class="col-12 col-md-2"> <label for="nombreControl" class="font-weight-bold">Lunes:</label> </div>
          <div class="col-12 col-md-2">  
            <InputSwitch v-model="configSemana[0].estatus" class="mt-2" />
          </div>
          <div class="col-12 col-md-4 mb-2"> <input type="time" class="form-control" id="nombreControl"  pattern="(2[0-3]|[01]?[0-9]):[0-5][0-9]" v-model="configSemana[0].hora_inicio"/></div> 
          <div class="col-12 col-md-4 mb-2"> <input type="time" class="form-control" id="nombreControl"  pattern="(2[0-3]|[01]?[0-9]):[0-5][0-9]" v-model="configSemana[0].hora_fin"/></div> 
        </div>
        <div class="row" v-if="item_control.id==1"> 
          <div class="col-12 col-md-2"> <label for="nombreControl" class="font-weight-bold">Martes:</label> </div>
          <div class="col-12 col-md-2">  
            <InputSwitch v-model="configSemana[1].estatus" />
          </div>
          <div class="col-12 col-md-4 mb-2"> <input type="time" class="form-control" id="nombreControl"  pattern="(2[0-3]|[01]?[0-9]):[0-5][0-9]" v-model="configSemana[1].hora_inicio"/></div> 
          <div class="col-12 col-md-4 mb-2"> <input type="time" class="form-control" id="nombreControl"  pattern="(2[0-3]|[01]?[0-9]):[0-5][0-9]" v-model="configSemana[1].hora_fin"/></div> 
        </div>
        <div class="row" v-if="item_control.id==1"> 
          <div class="col-12 col-md-2"> <label for="nombreControl" class="font-weight-bold">Miercoles:</label> </div>
          <div class="col-12 col-md-2">  
            <InputSwitch v-model="configSemana[2].estatus" />
          </div>
          <div class="col-12 col-md-4 mb-2"> <input type="time" class="form-control" id="nombreControl"  pattern="(2[0-3]|[01]?[0-9]):[0-5][0-9]" v-model="configSemana[2].hora_inicio"/></div> 
          <div class="col-12 col-md-4 mb-2"> <input type="time" class="form-control" id="nombreControl"  pattern="(2[0-3]|[01]?[0-9]):[0-5][0-9]" v-model="configSemana[2].hora_fin"/></div> 
        </div>
        <div class="row" v-if="item_control.id==1"> 
          <div class="col-12 col-md-2"> <label for="nombreControl" class="font-weight-bold">Jueves:</label> </div>
          <div class="col-12 col-md-2">  
            <InputSwitch v-model="configSemana[3].estatus" />
          </div>
          <div class="col-12 col-md-4 mb-2"> <input type="time" class="form-control" id="nombreControl"  pattern="(2[0-3]|[01]?[0-9]):[0-5][0-9]" v-model="configSemana[3].hora_inicio"/></div> 
          <div class="col-12 col-md-4 mb-2"> <input type="time" class="form-control" id="nombreControl"  pattern="(2[0-3]|[01]?[0-9]):[0-5][0-9]" v-model="configSemana[3].hora_fin"/></div> 
        </div>
        <div class="row" v-if="item_control.id==1"> 
          <div class="col-12 col-md-2"> <label for="nombreControl" class="font-weight-bold">Viernes:</label> </div>
          <div class="col-12 col-md-2">  
            <InputSwitch v-model="configSemana[4].estatus" />
          </div>
          <div class="col-12 col-md-4 mb-2"> <input type="time" class="form-control" id="nombreControl"  pattern="(2[0-3]|[01]?[0-9]):[0-5][0-9]" v-model="configSemana[4].hora_inicio"/></div> 
          <div class="col-12 col-md-4 mb-2"> <input type="time" class="form-control" id="nombreControl"  pattern="(2[0-3]|[01]?[0-9]):[0-5][0-9]" v-model="configSemana[4].hora_fin"/></div> 
        </div>
        <div class="row" v-if="item_control.id==1"> 
          <div class="col-12 col-md-2"> <label for="nombreControl" class="font-weight-bold">Sabado:</label> </div>
          <div class="col-12 col-md-2">  
            <InputSwitch v-model="configSemana[5].estatus" />
          </div>
          <div class="col-12 col-md-4 mb-2"> <input type="time" class="form-control" id="nombreControl"  pattern="(2[0-3]|[01]?[0-9]):[0-5][0-9]" v-model="configSemana[5].hora_inicio"/></div> 
          <div class="col-12 col-md-4 mb-2"> <input type="time" class="form-control" id="nombreControl"  pattern="(2[0-3]|[01]?[0-9]):[0-5][0-9]" v-model="configSemana[5].hora_fin"/></div> 
        </div>
        <div class="row" v-if="item_control.id==1"> 
          <div class="col-12 col-md-2"> <label for="nombreControl" class="font-weight-bold">Domingo:</label> </div>
          <div class="col-12 col-md-2">  
            <InputSwitch v-model="configSemana[6].estatus" />
          </div>
          <div class="col-12 col-md-4 mb-2"> <input type="time" class="form-control" id="nombreControl"  pattern="(2[0-3]|[01]?[0-9]):[0-5][0-9]" v-model="configSemana[6].hora_inicio"/></div> 
          <div class="col-12 col-md-4 mb-2"> <input type="time" class="form-control" id="nombreControl"  pattern="(2[0-3]|[01]?[0-9]):[0-5][0-9]" v-model="configSemana[6].hora_fin"/></div> 
        </div>


        <div class="row" v-if="item_control.id==53"> 
          <div class="col-12"> 
            <label for="nombreControl" class="font-weight-bold">CONTROL DE ENCUESTAS</label> 
             <div class="col-md-4"><label for="status">Estatus:</label></div>    
             <div class="col-md-8">
                <select class="form-control" id="status" v-model="configEncuesta.configuracion">
                  <option selected :value="1" >Diaria</option>
                  <option :value="2">Semanal</option>
                  <option :value="3">Mensual</option>
                </select>
             </div>

              <div class="col-md-8">
                <label for="">Total de envios:</label>
                <input type="number" class="form-control" id="nombreControl" v-model="configEncuesta.tope_encuestas">
              </div>
              <div class="col-md-8">
                <label for="">Vigencia de dias:</label>
                <input type="number" class="form-control" id="nombreControl" v-model="configEncuesta.vigencia_dias">
              </div>
          </div>
        </div>
        <!-- <div class="row">
                <div class="col-md-4"><label for="status">Estatus:</label></div>    
                <div class="col-md-8"><select class="custom-select" id="status" v-model="item_copy.status"><option selected :value="0" >Inactivo</option><option :value="1">Activo</option></select></div>
        </div>-->
      </div>
      <template #footer>
        <button class="btn btn-secondary" @click="closeModalControl">Salir</button>
        <button class="btn btn-success" @click="GuardarControl"  >Guardar</button> 
      </template>
    </Dialog>
    
  </div>
</template>

<style>
.btn-space {
  margin-right: 5px;
}
</style>
<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import controlesServices from "../../services/controles";
import Dialog from "primevue/dialog";
import Copys from "@/components/Dashboard/Tablas/Copys.vue";
import Parametros from "@/components/Dashboard/Tablas/Parametros.vue"; 

import {faPlay,faPause,faPlus,faTimes,faSave,faCopy,faCogs,faEdit} from "@fortawesome/free-solid-svg-icons";
library.add(faPlay, faPause, faPlus, faTimes, faSave, faCopy, faCogs, faEdit);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Toast from 'primevue/toast';
import InputSwitch from 'primevue/inputswitch'; 
import Swal from 'sweetalert';

export default {
  name: "ServiceControl",
  data() {
    return {
      isLoading:false,
      checked1:false,
      displayModalCopys: false,
      displayModalControl: false,
      configSemana: [],
      configEncuesta: [],
      displayModalParametros: false,
      controles: null,
      plataforma: 0,
      item_control: { id:null,nombre: null, descripcion: null },
      controlId: null,
      controlName: null,
      roles: JSON.parse(sessionStorage.getItem('sesion')),
      colaPyp:{
        min:-1,
        max:-1
      },
      selectEncuesta: null
    };
  },
  components: {
    FontAwesomeIcon,
    Dialog,
    Copys,
    Parametros,
    Toast,
    InputSwitch
  },
  originalRows: null,
  created() {
    this.originalRows = {};
  },
  mounted() {
    this.get();
  },
  methods: {
    showSuccess() {
      console.log('entro al toast')
            //this.$toast.add({severity:'success', summary: 'Operación completada', detail:'la acción se ha completado correctamente', life: 3000});
            Swal('Operación completada', '', 'success');
        },
        showError() {
            this.$toast.add({severity:'error', summary: 'Error', detail:'Ha ocurrido un error durante el proceso', life: 3000});
        },
    get() {
      this.controles = null;
      if (this.plataforma == 0) {
        controlesServices.Control().then((resp) => {
          //mario urias
          this.controles = this.roles.rol_id == 2 ? resp.filter(x => (x.id == 1 || x.id == 2 || x.id == 7 || x.id == 12|| x.id == 53)) : this.controles = resp
          console.log("contenido array")
          console.log(this.controles)
          this.configSemana = this.controles.filter(f => f.id == 1)[0].config 
          
          this.configEncuesta = this.controles.filter(f => f.id == 53)[0].config 
          console.log("CONFIG ENCUENSTA")
          console.log(this.configEncuesta)
        });
      }
    },
    seleccionarWA() {
      this.plataforma = 0;
      this.get();
    },
    seleccionarFB() {
      this.plataforma = 1;
      this.get();
    },
    openModalCopys(id,name) {
      this.controlId = id;
      this.controlName=name;
      this.displayModalCopys = true;
    },
    closeModalCopys() {
      this.displayModalCopys = false;
    },
    openModalParametros(id,name) {
      this.controlId = id;
      this.controlName=name;
      this.displayModalParametros = true;
    },
    closeModalParametros() {
      this.displayModalParametros = false;
    },
    btnAgregar() {
      this.agregar = !this.agregar;
    },
    cambiarEstatus(id, estatus,index) {
      if (this.plataforma == 0) {
        controlesServices.CambiarEstatusControl(id, estatus).then(res=>{
          if(res.status==200){
              this.controles[index].status=!this.controles[index].status;
              this.showSuccess();
          }
        });
      }
    },
    openModalControl(control,index) {
      if (control != null) {
        this.item_control.id=control.id
        this.item_control.nombre = control.nombre;
        this.item_control.descripcion = control.descripcion;
        this.item_control.index=index;

      }
      if(control.id==7){
        controlesServices.getControl().then((resp) => {
            this.colaPyp = resp.data
        });
      }
      this.displayModalControl = true;
    },
    closeModalControl() {
      this.displayModalControl = false;
    },
    GuardarControlHorarios() { 

      //obtener el valor actual del control.+
       controlesServices.getControl().then((resp) => {
            this.colaPyp = resp.data
        });
      
      const data = {asesor_id :JSON.parse(sessionStorage.getItem('sesion')).id ,config : this.configSemana} 
      this.isLoading=true
      try{
            controlesServices.configControlAsesor(data).then((res) => {
                console.log(res)
                this.showSuccess();
                this.isLoading=false
          })
      }catch(e){
        this.isLoading=false
      }
    },
    async GuardarLimiteEncuesta() { 

      let dataActual;
      const controles = await controlesServices.Control();
      dataActual = controles.find(control => control.id === 53);

      console.log(dataActual)
      
      const data = {
        asesor_id: JSON.parse(sessionStorage.getItem('sesion')).id,
        control_id: this.item_control.id,
        config: this.configEncuesta,
        dataActual: dataActual.config
      } 
      try{
        controlesServices.configLimiteEncuestas(data).then((res) => {
            console.log(res)
            this.showSuccess();
            this.isLoading=false
      })
      }catch(e){
        this.isLoading=false
      }
    },
    
    GuardarControl() {
      if(this.item_control.id!=undefined){
        if (this.item_control.id == 1) {
          this.GuardarControlHorarios()
        } else if (this.item_control.id == 7) {
          let activar = {
            asesor_id: this.roles.id,
            cola_minima: parseInt(this.colaPyp.min),
            cola_maxima: parseInt(this.colaPyp.max)
          }
          controlesServices.updateoControlPyP(activar).then((res) => {
            res.data ? this.displayModalControl = false : this.displayModalControl = true
          });

        } else if (this.item_control.id == 53) { 
         this.GuardarLimiteEncuesta()
        }else{
          let datos={controlId:this.item_control.id,nombre:this.item_control.nombre,descripcion :this.item_control.descripcion}
          controlesServices.updateoControl(datos).then((res) => {
            if(res!=null){
              this.showSuccess();
              this.controles[this.item_control.index].nombre=datos.nombre;
              this.controles[this.item_control.index].descripcion=datos.descripcion;
              // this.controles.push(res);
              this.displayModalControl = false;
            }
            else{
              this.showError();
            }
          });
        }
        
      }else{
        
        let datos={nombre:this.item_control.nombre,descripcion :this.item_control.descripcion}
        controlesServices.agregarControl(datos).then((res) => {
          if(res!=null){
            this.controles.push(res);
            this.displayModalControl = false;
            this.showSuccess();
          }
          else{
            this.showError();
          }
        });
      }
    },
  },
};
</script>
