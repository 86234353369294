let config = {
    BASEURL: process.env.BASEURL || 'https://back-asesor.salud-digna.site',// 'https://whatsapp-reportes-vgrynadla0vi.herokuapp.com'////, 
    //https://back-asesor.salud-digna.site
    //localhost: 'http://localhost:5000'
    baseurlFB: 'https://fb-digital.salud-digna.site',
    baseurlReportes: '',//'https://reportes.salud-digna.site',
    baseurlSIS: 'https://d7ad7b72c8ab.ngrok.io',
    baseDevoluciones: 'https://devolucion-api.salud-digna.site',
    baseDashboard: 'https://api-dashboard.salud-digna.site',
    baseMarketing: 'https://api.emarketingsd.org',
    baseHSM: 'https://api-hsm.salud-digna.site'
    

}

export default config