<template>
<div class>
    <DataTable :value="conversacion" ref="conversaciones" :filters="filters" :paginator="true" :loading="status" :rows="8" :selection.sync="seleccionado" selectionMode="single"  data-key="senderid" @row-select="onRowSelect" @row-unselect="onRowUnselect" class="p-datatable-responsive">
        <template #header>
            Conversaciones
            <div style="text-align: left">
                <i class="pi pi-search" style="margin: 4px 4px 0px 0px;"></i>
                <InputText v-model="filters['global']" placeholder="Filtro global" size="30" />
            </div>
            <div style="text-align: right">
                <Button class="btn btn-success top10" label="Export" @click="exportCSV($event)">
                    <span class="d-none d-sm-none d-md-block">Exportar a Excel</span> <font-awesome-icon class="d-block d-sm-block d-md-none" icon="file-excel"/>
                </Button>
            </div>
        </template>
        <template #loading>
            <span class="loading-text">Hola</span>
        </template>
        <Column field="nombre" header="Paciente" :sortable="true"></Column>
        <Column field="senderid" header="Numero de telefono" :sortable="true"></Column>
        <Column field="asesor" header="Atendido por" :sortable="true"></Column>
        <!-- Column class="d-none" field="rEncuesta" header="Calificación de encuesta" :sortable="true"  >
            <Rating :value="rEncuesta" :stars="5" :cancel="false" :readonly="true" />
      </Column-->
        <Column field="fecha" header="Hora y fecha" :sortable="true"></Column>
    </DataTable>
    <Dialog class="conversaciones" :closable="false" :modal="true" :header="'Conversación de ['+this.nombre+'] de numero  +'+this.numero+' con '+this.asesor+' el dia de '+this.fecha" :visible.sync="chat">
        <Mensajes style="z-index:1044"/>
        <template #footer>
             <Button v-if="grupo_id == 2" class="btn btn-primary btn-sm float-left" @click="inbox"  data-toggle="modal" data-target="#traspasarChat">
                <font-awesome-icon class="mr-1" icon="reply" /><span class="ocultoMovil"> Enviar con asesor</span>
             </Button>
             <Button v-if="grupo_id == 2" class="btn btn-info btn-sm float-left" @click="bot">
                <font-awesome-icon class="mr-1" icon="robot" /><span class="ocultoMovil"> Enviar al bot</span>
             </Button>
             <Button :disabled="isLoading"  class="btn btn-success btn-sm" @click="revivirSesion()">
               <i v-if="!isLoading" class="icon-sd-send"></i>
               <span v-if="isLoading">Enviando...</span>
               <span v-else class="ocultoMovil">Enviar Plantilla</span>
             </Button>
            <Button class="btn btn-danger btn-sm" @click="cerrar">
                <font-awesome-icon class="mr-1" icon="times" /><span class="ocultoMovil">Cerrar conversacion</span>
            </Button>
        </template>
    </Dialog>
</div>
</template>
<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Mensajes from "../../Mensajes.vue";
import asesorServices from '../../../services/asesor';
// import MultiSelect from 'primevue/multiselect';
import InputText from "primevue/inputtext";
//import Rating from 'primevue/rating';
import Dialog from "primevue/dialog";
import reportesServices from "../../../services/reportes"
import mensaje from "../../../services/mensaje"
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
import {
    library
} from "@fortawesome/fontawesome-svg-core";
import {
    faTimes,
    faReply, faFileExcel
} from "@fortawesome/free-solid-svg-icons";
library.add(faTimes, faReply, faFileExcel);
export default {
    name: "TConversaciones",
    components: {
        DataTable: DataTable,
        Column: Column,
        // 'MultiSelect': MultiSelect,
        InputText: InputText,
        "font-awesome-icon": FontAwesomeIcon,
        // Rating,
        Dialog,
        Mensajes
    },
    props: {
        fechaI :null,
        fechaF: null,
        origen: null
    },
    data() {
        return {
            filters: {},
            conversacion: [{"id":-1,"nombre":"Selecciona","senderid":"una fecha","fecha":"el historial","asesor_id":null,"ejecutivo":null,"asesor":"para ver"}],
            seleccionado: null,
            chat: false,
            numero: null,
            nombre: '',
            asesor: '',
            fecha: '',
            status: false,
            grupo_id: null,
            asesor_id: null,
            isLoading: false,
            hsm_id: null
        };
    },
    created() {
        this.$bus.$off('updateHistorial')
        this.$bus.$on('updateHistorial', () => {
            this.status = true
            this.cargarHistorialChats()

            if (this.origen == 1) {
                this.hsm_id = 51
                console.log(this.origen)
            }
            if (this.origen  == 5) {
                this.hsm_id = 19
            }
        })

        const sesion = JSON.parse(sessionStorage.getItem('sesion'));
        this.grupo_id = sesion ? sesion.grupo_id : null;
        this.asesor_id = sesion ? sesion.id : null;
        
      
        // this.$bus.$off('abrirConversacion')
        // this.$bus.$on('abrirConversacion', () => {
        //     this.status=true
        //     this.cargarHistorialChats()
        // })
    },
    methods: {
        exportCSV() {
            this.$refs.conversaciones.exportCSV();
        },
        abrir() {
            this.chat = true;
        },
        cerrar() {
            this.chat = false;
            this.seleccionado = null;
        },
        inbox() {
            sessionStorage.setItem('sender_actual',this.seleccionado.senderid)  
            this.$bus.$emit("abrirInbox")
        },
        bot(){
            asesorServices.flujos(0)
        },
        onRowSelect() {
            if(this.seleccionado.id!=-1){
                this.chat = true;
                this.numero = this.seleccionado.senderid;
                this.nombre = this.seleccionado.nombre;
                if (this.seleccionado.asesor == '') {
                    this.asesor = 'Asistente Virtual'
                } else {
                    this.asesor = this.seleccionado.asesor;
                }
                this.fecha = this.seleccionado.fecha;
                sessionStorage.setItem('sender_actual',this.numero)  
                this.$bus.$emit("abrirConversacion")
            }else{
                this.onRowUnselect()
            }
        },
        onRowUnselect() {
            this.seleccionado = null;
        },
        cargarHistorialChats() {
            reportesServices.getHistorialChats(this.fechaI,this.fechaF,this.origen).then(resp => {
                var moment = require('moment')
                moment.locale('es')
                resp = resp.map(chat =>{
                    chat.asesor = chat.ejecutivo ? chat.ejecutivo.nombre: 'Asistente Virtual'
                    chat.fecha = moment(chat.fecha).tz("America/Mazatlan").format('YYYY MMMM DD, dddd, hh:mm:ss a');
                    return chat
                })
                this.conversacion = resp;
                this.status=false
                this.$bus.$emit('respuestaStatus', true)
            });
        },
       revivirSesion() {
        this.isLoading = true;
        mensaje.envio(this.asesor_id, this.numero, this.origen, this.hsm_id).then(hsm => {
            this.chat = hsm.status;
            setTimeout(() => {
                this.isLoading = false;
            }, 5000);
        }).catch(error => {
            // Manejar el error
            console.error(error);
            this.isLoading = false; // Asegurarse de que isLoading se establezca en false en caso de error
        });
        }
    },
    mounted() {
        //this.cargarHistorialChats();
        this.$bus.$emit('respuestaStatus', true)
    }
};
</script>
