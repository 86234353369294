<template>
    <div v-if="this.diacumpleaños==1" class="container-fluid align-self-center loginCumpleaños" >
        <!-- <div class="nevado"></div> -->
        <div class="row">
            <div class="col">
                <div class="rectangulo" style="margin-top: 15px;">
                    <img class="logoBlanco" src="../assets/images/logoLogin.svg" style="height:110px; bottom:30px">
                </div>
            </div>
        </div>
        <div class="container contLogin" >
            <div class="row">
                <div class="">
                    <form id="formulario" @submit.prevent="login">
                        <div class="form-group d-flex flex-column justify-content-center align-items-center">
                            <div class="user align-self-center">
                                <img v-if="ultimaVez==null" src="../assets/images/favicon.png">
                                <img v-else class="rounded-circle imgPerfilAsesor" :src="'https://sd-storage.sfo2.digitaloceanspaces.com/PLATAFORMA-INBOX/imagenes/asesores/'+ultimaVez.id+'.jpg'" style="height: 390px;width:390px;margin-left: 398px;">
                                <div v-show="ultimaVez!=null" @click="eliminarLocal" class="rounded-circle bg-danger btnCerrarLogin" title="Ingresar con otro usuario" style="margin-left: 1110px"><font-awesome-icon icon="times-circle" /></div>
                            </div>
                            
                            <div class="alert alert-danger mb-none alert-dismissible fade" :class="{show: this.alert}" role="alert">
                                {{error.data.msj?error.data.msj:'Error, comunicarse a soporte'}}
                                <button @click="cerrar" type="button" class="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <span class="d-none text-danger">*Usuario o contraseña incorrecta</span>
                        </div>
                        <div class="row mb-2" v-show="!ultimaVez || !ultimaVez.tieneuid">
                            <div class="contPass" style="margin-left: 23px; margin-top: 150px;">
                                <i v-if="status" class="icon-contraseña"></i>
                                <span v-else class="spinner-border spinner-border-sm ml-2 mr-2" role="status" aria-hidden="true"></span>
                                <input type="password" v-model="pass" class="inputPass required" placeholder="Contraseña" required size="28">
                            </div>
                        </div>
                        <input v-show="!ultimaVez || !ultimaVez.tieneuid" type="submit" id="enviar" value="Iniciar sesión" class="btn btn-primary btnLogin">
                    </form>
                    <button @click="iniciarConGoogle" class="btn btn-light ml-3 btnLogin text-primary" style="position: fixed; margin-left: 7px !important;"> Iniciar sesión con <img src="../../public/1534129544.svg" style="max-height: 35px;"></button>
                    <div class="row mb-2" v-if="ultimaVez==null" >
                        <div class="contUsuario">
                            <i v-if="status" class="icon-usuario"></i>
                            <span v-else class="spinner-border spinner-border-sm ml-2 mr-2" role="status" aria-hidden="true"></span>
                            <input type="text" v-model="user" class="inputUser required" placeholder="Correo institucional" required>
                        </div>
                    </div>
                    <div v-show="!ultimaVez || !ultimaVez.tieneuid" class="my-4" style="padding-top: 80px;margin-left: 693px;">
                        <h1><b style="color:#ab8761;font-size: 100px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{ultimaVez.nombre}}</b></h1>
                    </div>
                    <div v-show="ultimaVez || ultimaVez.tieneuid" class="my-4" style="padding-top: 420px;margin-left: 693px;">
                        <h1><b style="color:#ab8761;font-size: 100px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{ultimaVez.nombre}}</b></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="this.diaaniversario==1" class=" align-self-center loginAniv" id="fondoaniv">
        <img src="../assets/images/aniversario.png" style=" position: absolute;z-index: 1; width: 100vw; max-height: 100vh ;object-fit: cover;">
        <!-- <div class="nevado"></div> -->
        <div class="row">
            <div class="col">
                <div class="rectangulo enfrente">
                    <img class="logoBlanco" src="../assets/images/logoLogin.svg" style="height:110px; bottom:30px">
                </div>
            </div>
        </div>
        <div class="form-group d-flex flex-column justify-content-center align-items-center">
            <div class="user align-self-center">
                <img v-if="ultimaVez==null" src="../assets/images/favicon.png">
                <img v-else class="rounded-circle imgPerfilAsesor" :src="'https://sd-storage.sfo2.digitaloceanspaces.com/PLATAFORMA-INBOX/imagenes/asesores/'+ultimaVez.id+'.jpg'" style="height: 380px;width: 380px;margin-left: -600px;position: relative; z-index:0">
                
            </div>
            
            <div class="alert alert-danger mb-none alert-dismissible fade enfrente" :class="{show: this.alert}" role="alert" style="margin-left: 670px;">
                {{error.data.msj?error.data.msj:'Error, comunicarse a soporte'}}
                <button @click="cerrar" type="button" class="close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <span class="d-none text-danger enfrente">*Usuario o contraseña incorrecta</span>
            </div>
        </div>
        <div class="container contLogin enfrente ">
            <div v-show="ultimaVez!=null" @click="eliminarLocal" class="rounded-circle bg-danger btnCerrarLogin" title="Ingresar con otro usuario" style="margin-left: 166px;margin-top: -159px;"><font-awesome-icon icon="times-circle" /></div>
            <div class="row">
                <div class="">
                    <form id="formulario" @submit.prevent="login">
                        <div v-show="!ultimaVez || !ultimaVez.tieneuid" class="row mb-2"> 
                            <div class="contPass enfrente" style="margin-left: 1000px; margin-top: 150px;">
                                <i v-if="status" class="icon-contraseña"></i>
                                <span v-else class="spinner-border spinner-border-sm ml-2 mr-2" role="status" aria-hidden="true"></span>
                                <input type="password" v-model="pass" class="inputPass required" placeholder="Contraseña" required size="28">
                            </div>
                        </div>
                        <input v-show="!ultimaVez || !ultimaVez.tieneuid" type="submit" id="enviar" value="Iniciar sesión" class="btn btn-primary btnLogin enfrente" style="margin-left: 990px;">
                    </form>
                    <div class="row mb-2 enfrente" v-if="ultimaVez==null">
                        <div class="contUsuario">
                            <i v-if="status" class="icon-usuario"></i>
                            <span v-else class="spinner-border spinner-border-sm ml-2 mr-2" role="status" aria-hidden="true"></span>
                            <input type="text" v-model="user" class="inputUser required" placeholder="Correo institucional" required>
                        </div>
                    </div>
                    <div v-if="!ultimaVez || !ultimaVez.tieneuid" class="my-4 enfrente" style="padding-top: 90px;margin-left: 40px;">
                        <h1><b style="color:#ab8761;font-size: 100px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{ultimaVez.nombre}}</b></h1>
                    </div>
                    <div v-show="ultimaVez && ultimaVez.tieneuid" class="my-4 enfrente" style="position: relative; top: 150%; left: 5%;">
                        <h1><b style="color:#ab8761;font-size: 100px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{ultimaVez.nombre}}</b></h1>
                    </div>
                    <div v-if="!ultimaVez || !ultimaVez.tieneuid" class="d-flex justify-content-end" style="position: relative; bottom: 40%;">
                        <button @click="iniciarConGoogle" class="btn btn-light btnLogin text-primary"> Iniciar sesión con <img src="../../public/1534129544.svg" style="max-height: 35px;"></button>
                    </div>
                    <div v-show="ultimaVez && ultimaVez.tieneuid" class="d-flex justify-content-end" style="position: relative; left: 100%;">
                        <button @click="iniciarConGoogle" class="btn btn-light btnLogin text-primary">Iniciar sesión con <img src="../../public/1534129544.svg" style="max-height: 35px;"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="container-fluid align-self-center login" :class="{'loginDiaEspecial' : diaEspecial, 'loginMedicos':dominio=='dr-chat.sdmkt.org'}" >
        <!-- <div class="nevado"></div> -->
        <div class="row">
            <div class="col">
                <div class="rectangulo">
                    <img v-if="network!='WApetct'" class="logoBlanco" src="../assets/images/logoLogin.svg" style="height:110px; bottom:30px">
                    <img v-else class="logoBlanco" src="../assets/images/logoPetCT.svg" style="height:70px; bottom:10px">
                </div>
            </div>
        </div>
        <div class="contLogin">
            <div class="row">
                <div class="contenedorLogin">
                    <form id="formulario" @submit.prevent="login">
                        <div class="form-group d-flex flex-column justify-content-center align-items-center">
                            <div class="user align-self-center">
                                <img v-if="ultimaVez==null" src="../assets/images/favicon.png">
                                <img v-else class="rounded" :src="'https://sd-storage.sfo2.digitaloceanspaces.com/PLATAFORMA-INBOX/imagenes/asesores/'+ultimaVez.id+'.jpg'">
                                <div v-show="ultimaVez!=null" @click="eliminarLocal" class="rounded-circle bg-danger btnCerrarLogin" title="Ingresar con otro usuario"><font-awesome-icon icon="times-circle" /></div>
                            </div>
                            <div class="row mb-2" v-if="ultimaVez==null">
                                <div class="contUsuario">
                                    <i v-if="status" class="icon-usuario"></i>
                                    <span v-else class="spinner-border spinner-border-sm ml-2 mr-2" role="status" aria-hidden="true"></span>
                                    <input type="text" v-model="user" class="inputUser required" placeholder="Correo institucional" required>
                                </div>
                            </div>
                            <div v-else class="my-4">
                                <h5><b style="color:#FFFFFF">{{ultimaVez.nombre}}</b></h5>
                            </div>
                            <div class="row mb-2">
                                <div v-show="!ultimaVez || !ultimaVez.tieneuid" class="contPass">
                                    <i v-if="status" class="icon-contraseña"></i>
                                    <span v-else class="spinner-border spinner-border-sm ml-2 mr-2" role="status" aria-hidden="true"></span>
                                    <input type="password" v-model="pass" class="inputPass required" placeholder="Contraseña" required size="28">
                                </div>
                            </div>
                            <div class="alert alert-danger mb-none alert-dismissible fade" :class="{show: this.alert}" role="alert">
                                {{error.data.msj?error.data.msj:'Error, comunicarse a soporte'}}
                                <button @click="cerrar" type="button" class="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <span class="d-none text-danger">*Usuario o contraseña incorrecta</span>
                        </div>
                        <input v-show="!ultimaVez || !ultimaVez.tieneuid" type="submit" id="enviar" value="Iniciar sesión" class="btn btn-primary btnLogin" style="position: absolute; top: 250px; left: 20px">
                        <div>
                            <button @click="iniciarConGoogle" class="btn btn-light btnLogin text-primary" style="position: absolute; top: 320px; left: 20px"> Iniciar sesión con <img src="../../public/1534129544.svg" style="max-height: 35px;"></button>
                        </div>
                    </form>
                    
                </div>
            </div>
        </div>
    </div>
    </template>
<style>
.enfrente{
position: relative;
z-index: 3;
}
.contenedorLoginCump {
  border-radius: 20px;
  display: flex;
  justify-content: right;
  width: 400px; }

.login.loginMedicos {
    background-image: url("../assets/images/login-Medicos.png");
}
.contenedorLogin{
    margin-left: 13px
}
</style>

<script>
import loginService from '../services/login'
import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
library.add(faTimesCircle)
import { signin } from '../firebase/auth_google.js'
export default {
    name: 'LoginComponent',
    data() {
        return {
            user: '',
            pass: '',
            diaEspecial: false,
            hoy: new Date(),
            error: {
                data:{
                    msj:null
                }
            },
            alert: false,
            status:true,
            passEncriptada: null,
            ultimaVez:JSON.parse(localStorage.getItem('sesionAnterior')),
            diacumpleaños:0,
            diaaniversario:0,
            dominio: window.location.hostname,
            network: localStorage.getItem('Network')
        }
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon
    },
    created(){
        this.dia()
            let moment = require('moment')
            let fechahoy = moment(this.hoy).format("MM-DD").toString().trim()
            let fechacum  
            let fechaaniv
            if(this.ultimaVez){
                fechacum = this.ultimaVez.fecha_nacimiento ? moment(this.ultimaVez.fecha_nacimiento).format("MM-DD").toString().trim() : undefined 
                fechaaniv = this.ultimaVez.fecha_nacimiento ? moment(this.ultimaVez.fecha_aniversario).format("MM-DD").toString().trim() : undefined
            }
        if(fechahoy==fechacum){
            this.diacumpleaños=1
        }
        if(fechahoy==fechaaniv){
            this.diaaniversario=1
        }
    },
    methods: {
        iniciarConGoogle(){
            signin().then( res=> {
                this.login(res)
            })
        },
        dia(){
            if ('33' == this.hoy.toString().substr(8, 2)||'32' == this.hoy.toString().substr(8, 2)){
                this.diaEspecial = true
            }
        },
        cerrar(){
            this.alert = false
        },
        // encriptar(){
        //     const bcrypt = require('bcrypt')
        //     this.passEncriptada = bcrypt.hashSync(this.pass, 10)
        //     console.log(this.passEncriptada)
        // },
        login(googleSign) {
            this.status=false
            let email, pass, funcion
            if(this.ultimaVez!=null){
                this.user=this.ultimaVez.email
            }
            if(googleSign.providerId) {
                email = googleSign.email
                pass = googleSign.uid 
                funcion = 'loginGoogle'
            }
            else{
                if(this.user.indexOf('@')==-1){
                    this.user+='@salud-digna.org'
                }
                email = this.user.toLowerCase()
                pass = this.pass
                funcion = 'login'
            }

            loginService[funcion](email, pass).then(res => {
                let datosUser = {
                    id: res.user.id,
                    nombre: res.user.nombre,
                    email: res.user.email,
                    rol_id: res.user.rol_id,
                    estatus: res.user.estatus,
                    oculto: res.user.respuestas==1 ? false :true ,
                    lastconnection: res.user.lastconnection,
                    createdAt: res.user.createdAt,
                    updatedAt: res.user.updatedAt,
                    token:  res.accesToken,
                    grupo_id: res.user.grupo_id,
                    horario:res.user.horario,
                    fecha_nacimiento:res.user.fecha_nacimiento,
                    fecha_aniversario:res.user.fecha_aniversario
                }
                sessionStorage.clear()
                sessionStorage.setItem('sesion', JSON.stringify(datosUser))
                sessionStorage.setItem('asesorId', res.user.id)
                sessionStorage.setItem('token', res.accesToken)
                this.guardarEnLocal(res.user.id, res.user.nombre,res.user.fecha_nacimiento,res.user.fecha_aniversario, googleSign.providerId ? true : false)

                if(res.user.grupo_id==6)
                    window.location.href = '/dashboard/parametros'
                else if(res.user.rol_id==4)
                    window.location.href = '/chat'
                else if(res.user.grupo_id==10 && res.user.rol_id==3)
                    window.location.href = '/chat'
                else if(res.user.grupo_id==7||res.user.grupo_id==8||res.user.grupo_id==9)
                    window.location.href = '/chat'
                else if(res.user.rol_id==1 || res.user.rol_id==2 || res.user.rol_id==4)
                    window.location.href = '/dashboard'
                else if(res.user.rol_id==3 && res.user.grupo_id==2)
                    window.location.href = '/inicio'
                else
                    window.location.href = '/chat'
            }).catch(err => {
                this.alert = true
                this.status= true
                if (err == 'Error: Network Error'){
                    this.error = 'No hay conexión con servidor'
                }else if(err == 'Error: Request failed with status code 404'){
                    this.error = 'Usuario o contraseña incorrecto'
                }else if(err == 'Error: Request failed with status code 500'){
                    this.error = 'Reintentar en 1 minuto, Error 500'
                    this.$gtag.event('errores',{
                        'event_category': 'error',
                        'event_label': 'login500',
                        'value': 500
                    })
                }
                else {
                    this.error = err
                }
            })
        },
        guardarEnLocal(id, nombre,fecha_nacimiento,fecha_aniversario, googleSign){
            let info={
                email: this.user,
                id: id,
                nombre: nombre,
                fecha_nacimiento:fecha_nacimiento,
                fecha_aniversario:fecha_aniversario,
                tieneuid: googleSign
            }
            localStorage.setItem('sesionAnterior', JSON.stringify(info))
        },
        eliminarLocal(){
            localStorage.clear()
            location.reload()
            this.ultimaVez=null
        }
    },
}
</script>
