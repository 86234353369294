<template>
<div class="container-fluid">
    <div  class="my-2">

        <div class="row" >
            <h5 class="mx-4"><font-awesome-icon icon="moon"/>  Dormir opción Asesor:</h5> <InputSwitch v-model="checked" @click="cambiarSwitch" /> 
          <div class="col-6 col-lg-4 font-weight-bolder" style="font-size: 14px">
                <tb> Px máximos generales </tb><button class="btn btn-primary btn-sm" @click="editarGeneral()"><font-awesome-icon icon="edit"/></button>
           </div>
        </div>

        <!-- <div class="p-4 d-flex flex-row w-100 justify-content-center align-items-center row">
           <div class="col-3 col-lg-3 font-weight-bolder" style="font-size: 14px">
                Cola Whatsapp MX: <b>{{cola.whatsapp.cola}}</b><br>
                Real (Asesor + Cola): {{pedircola.cola}}
           </div>
           <div class="col-3 col-lg-3 font-weight-bolder" style="font-size: 14px">
                Cola Whatsapp NI: <b>{{nicaragua.cola}}</b>
           </div>
           <div class="col-3 col-lg-3 font-weight-bolder" style="font-size: 14px">
                Cola Whatsapp SV: <b>{{salvador.cola}}</b>
           </div>
           <div class="col-3 col-lg-3 font-weight-bolder" style="font-size: 14px">
                Cola Facebook: <b>{{cola.facebook.cola}}</b>
           </div>
           <div class="col-3 col-lg-3 font-weight-bolder" style="font-size: 14px">
                Cola Hemodialisis: <b>{{hemodialisis.cola}}</b>
           </div>
           <div class="col-3 col-lg-3 font-weight-bolder" style="font-size: 14px">
                Cola PetCt: <b>{{petct.cola}}</b>
           </div> 
           <div class="col-3 col-lg-3 font-weight-bolder" style="font-size: 14px">
                Cola Guatemala: <b>{{gml.cola}}</b>
           </div> 
        </div> -->

        <div class="p-4 d-flex flex-row w-100 justify-content-center align-items-center row">
           <div class="col-3 col-lg-3 font-weight-bolder" style="font-size: 14px">
                Cola Whatsapp MX:  <b>{{ cola && cola.whatsapp && cola.whatsapp.cola !== null ? cola.whatsapp.cola : 'Cargando...' }}</b><br>
                Real (Asesor + Cola): {{pedircola && pedircola.cola !== null ? pedircola.cola : 'Cargando...' }}
           </div>

            <div v-if="!nicaragua || !nicaragua.cola" class="col-3 col-lg-3 font-weight-bolder" style="font-size: 14px">
                Cola Whatsapp NI: Cargando...
            </div>
            <div v-else class="col-3 col-lg-3 font-weight-bolder" style="font-size: 14px">
                Cola Whatsapp NI: <b>{{ nicaragua.cola }}</b>
            </div>

            <div v-if="!salvador || !salvador.cola" class="col-3 col-lg-3 font-weight-bolder" style="font-size: 14px">
                Cola Whatsapp SV: Cargando...
            </div>
            <div v-else class="col-3 col-lg-3 font-weight-bolder" style="font-size: 14px">
                Cola Whatsapp SV: <b>{{salvador.cola}}</b>
            </div>

           <div class="col-3 col-lg-3 font-weight-bolder" style="font-size: 14px">
                Cola Facebook: <b>{{ cola && cola.facebook && cola.facebook.cola !== null ? cola.facebook.cola : 'Cargando...' }}</b>
           </div>
           <div class="col-3 col-lg-3 font-weight-bolder" style="font-size: 14px">
                Cola Hemodialisis: <b>{{hemodialisis && hemodialisis.cola !== null ? hemodialisis.cola: 'Cargando...'}}</b>
           </div>
           <div class="col-3 col-lg-3 font-weight-bolder" style="font-size: 14px">
                Cola PetCt: <b>{{petct && petct.cola !== null ? petct.cola: 'Cargando...'}}</b>
           </div> 
           <div class="col-3 col-lg-3 font-weight-bolder" style="font-size: 14px">
                Cola Guatemala: <b>{{gml && gml.cola !== null ? gml.cola: 'Cargando...'}}</b>
           </div> 
        </div>
        
        <table class="table table-hover table-reflow table-bordered">
            <thead class="thead-light">
                <tr>
                    <th>Asesor</th>
                    <th>Inbox <i class="icon-whatsapp" style="font-size:14px"></i></th>
                    <th>En pendientes</th>
                    <th>Automatización <i class="icon-whatsapp" style="font-size:14px"></i></th>
                    <!-- <th>Automatización <i class="icon-messenger-new"></i></th> -->
                    <th>Controles <i class="icon-whatsapp" style="font-size:14px"></i></th>
                    <!-- <th>Controles <i class="icon-messenger-new"></i></th> -->
                    <th>MAX # PX</th>
                    <th>Tipo de Cola</th>
                    <th>Editar</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="asesor in asesores" :key="asesor.id">
                    <th scope="row">
                        <b class="h5">
                            {{asesor.nombre}}
                            <i v-if="asesor.rol_id==3">
                                <a v-if="asesor.ultimaEmocion==1" class="pt-5 my-2" style="font-size: 1em"> &nbsp;
                                    <font-awesome-icon icon="laugh" style="color:#75d27f"/>
                                </a>
                                <a v-if="asesor.ultimaEmocion==2" class="pt-5 my-2" style="font-size: 1em"> &nbsp;
                                    <font-awesome-icon icon="smile" style="color:#7fe1c5"/>
                                </a>
                                <a v-if="asesor.ultimaEmocion==3" class="pt-5 my-2" style="font-size: 1em"> &nbsp;
                                    <font-awesome-icon icon="meh" style="color:#ffc900" />
                                </a>
                                <a v-if="asesor.ultimaEmocion==4" class="pt-5 my-2" style="font-size: 1em" > &nbsp;
                                    <font-awesome-icon icon="sad-cry" style="color:#fb9a27" /> 
                                </a>
                                <a v-if="asesor.ultimaEmocion==5" class="pt-5 my-2" style="font-size: 1em"> &nbsp;
                                    <font-awesome-icon icon="tired" style="color:#ff7b7c"/> 
                                </a>
                                <!-- <a v-if="asesor.ultimaEmocion==6" class="pt-5 my-2" style="font-size: 1em"> &nbsp;
                                <font-awesome-icon icon="head-side-mask" style="color: #2fb32f"/>
                                </a>
                                <a v-if="asesor.ultimaEmocion==7" class="pt-5 my-2" style="font-size: 1em"> &nbsp;
                                <font-awesome-icon icon="sad-cry" style="color: #61c4e8 "/>
                                </a>
                                <a v-if="asesor.ultimaEmocion==8" class="pt-5 my-2" style="font-size: 1em"> &nbsp;
                                <font-awesome-icon icon="tired" style="color:#fd9e7a"/> 
                                </a>
                                <a v-if="asesor.ultimaEmocion==9" class="pt-5 my-2" style="font-size: 1em"> &nbsp;
                                <font-awesome-icon icon="angry" style="color: #ff6a6a "/>
                                </a> -->
                            </i>
                        </b>
                        <br>
                        <i v-if="asesor.horario"><p v-if="asesor.horario[diaSemana].status">Labora de {{asesor.horario[diaSemana].matutino[0]}} a {{asesor.horario[diaSemana].vespertino[1]}}hrs <br>
                        Hora de comida {{asesor.horario[diaSemana].matutino[1]}} a {{asesor.horario[diaSemana].vespertino[0]}}hrs
                        </p><p v-else>No labora</p></i><i v-else>Sin horario asignado</i>
                        <!-- <i v-if="asesor.rol_id==3">
                        Primer Mensaje <br>
                        <span>1er Turno: {{asesor.primerMensaje.entrada1}}</span><br>
                        <span>2do Turno: {{asesor.primerMensaje.entrada2}}</span><br>
                        </i> -->
                        <div class="row">
                            <div v-if="asesor.break.activo==1" class="col-1 d-flex justify-content-start">
                                <a class="badge badge-danger text-light d-flex justify-content-center align-items-center pointer" @click = "cerrarBreak(asesor.id)">Cerrar break</a>
                            </div>
                            <div class="col-10 ml-2">
                                <span v-if="asesor.ESTATUS_ASESOR==1" class="parpadea texto-verde">En linea</span>
                                <span v-else-if="asesor.ESTATUS_ASESOR==2" class="texto-verde text-warning">Inactivo</span>
                                <span v-else-if="asesor.ESTATUS_ASESOR==4" class="texto-naranja">Ausente</span>
                                <span v-else class="texto-verde text-danger">Desconectado</span>
                                <span v-if="asesor.break.activo==1" class="texto-naranja"><i class="icon-clock-1"/>Break - <span class="texto-naranja">{{asesor.break.motivo}} </span><span class="texto-naranja">{{asesor.break.desde}}</span></span>
                            </div>
                        </div>
                        <br>
                        <span v-show="asesor.max_tiempo!='Fecha inválida'">Paciente mas antiguo: {{asesor.max_tiempo}}</span>
                        
                        
                        <!-- <ul>
                            <li v-for="(hora, index) in asesor.horario" :key="index"><div v-show="true"><p v-if="hora.status"><b>{{index}}</b><br> Labora de: 🕙{{hora.matutino[0]}} a {{hora.vespertino[1]}} <br> Descanso de: ⌛{{hora.matutino[1]}} a {{hora.vespertino[0]}}</p><p v-else><b>{{index}}</b><br>No labora</p></div></li>
                        </ul> -->
                    </th>
                    <td class="h5">{{asesor.ATENDIENDO}}</td>
                    <td class="h5">{{asesor.ESPERA_1}}</td>
                    <td><div v-if="asesor.sesion" class="rounded bg-success text-white font-weight-bold p-2 text-center"> Funcionando <font-awesome-icon spin icon="cog"/></div><div v-else class="rounded bg-info text-white font-weight-bold p-2 text-center"> Detenida <font-awesome-icon icon="cog"/></div> </td>
                    <!-- <td><div v-if="asesor.facebook" class="rounded bg-success text-white font-weight-bold p-2 text-center"> Funcionando <font-awesome-icon spin icon="cog"/></div><div v-else class="rounded bg-info text-white font-weight-bold p-2 text-center"> Detenida <font-awesome-icon icon="cog"/></div> </td> -->
                    <td><button @click="cambiarEstatus(asesor.id, !asesor.sesion)" class="btn  btn-sm" :class="{'btn-info':!asesor.sesion, 'btn-secondary':asesor.sesion}"><font-awesome-icon v-if="asesor.sesion" icon="pause"/><font-awesome-icon v-else icon="play"/> <span v-if="asesor.sesion">Detener</span><span v-else>Iniciar</span> <span v-if="esperar" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                    <button @click="mandarCola(asesor.id)" class="btn btn-primary btn-sm ml-1">A cola</button>
                    </td>
                    <!-- <td><button @click="guardar(asesor.id, asesor.sesion, (asesor.facebook?0:1), asesor.num_pacientes, asesor.cola)" class="btn  btn-sm" :class="{'btn-info':!asesor.facebook, 'btn-secondary':asesor.facebook}"><font-awesome-icon v-if="asesor.facebook" icon="pause"/><font-awesome-icon v-else icon="play"/> <span v-if="asesor.facebook">Detener</span><span v-else>Iniciar</span> <span v-if="esperar" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button> </td> -->
                    <td class="h5">{{asesor.num_pacientes}} </td>
                    <td><h5 class="h5"><span class="badge badge-secondary"><font-awesome-icon v-show="asesor.cola=='ASC'" icon="arrow-alt-circle-up"/><font-awesome-icon v-show="asesor.cola=='DESC'" icon="arrow-alt-circle-down"/> {{asesor.cola=='ASC'?'Antiguos':'Nuevos'}}</span></h5></td>
                    <td><button class="btn btn-warning btn-sm" @click="editar(asesor)"><font-awesome-icon icon="edit"/></button></td>
                </tr>
            </tbody>
        </table>
    </div>
    
    <Sidebar :visible="panelLateral" position="right" class="lateralParametros">
        <h4><b>{{seleccionado.nombre}}</b></h4>
        <div class="contenidoAsesorParametros mt-4">
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text"># de px máximos</span>
                </div>
                <input v-model="seleccionado.num_pacientes" type="number" class="form-control">
            </div>
            <p class="h6 mt-3">Orden de los pacientes que recibirá:</p>
            <select v-model="seleccionado.cola" class="custom-select custom-select-sm">
                <option value="ASC">Antiguos</option>
                <option value="DESC">Nuevos</option>
            </select>
        </div>
        <button class="btn btn-secondary btn-sm float-left mt-2" @click="panelLateral=false">Cerrar</button>
        <button class="btn btn-success btn-sm float-right mt-2" @click="guardar(seleccionado.id, seleccionado.sesion, seleccionado.facebook, seleccionado.num_pacientes, seleccionado.cola)">Guardar <font-awesome-icon icon="save"/></button>
    </Sidebar>
    <Sidebar :visible="panelGeneral" position="right" class="lateralGeneral">
        <h4><b>Px máximos generales </b></h4>
       <div class="contenidoAsesorParametros mt-4">
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text"># de px máximos generales</span>
            </div>
             <input v-model="seleccionadoGen.num_pacientes" type="number" class="form-control">
            </div>
        </div>
            <ul class="list-group list-group-flush" style="margin: 9px;">
                <li class="list-group-item" v-for="list in listaAse" :key="list.id" style="padding: 7px">
                    <input type="checkbox"  v-model="list.seleccionado">
                    {{ list.nombre }}
                </li>
            </ul>
        <button class="btn btn-secondary btn-sm float-left mt-2" @click="panelGeneral=false">Cerrar</button>
         <button class="btn btn-success btn-sm float-right mt-2" @click="guardarGenEsp(seleccionadoGen.num_pacientes)">Guardar Generales <font-awesome-icon icon="save"/></button>   
    </Sidebar>
</div>
</template>
<style>
.texto-verde {
  font-size:14px;
  font-weight:bold;
  color:#4d9702;
}
.texto-naranja{
  font-size:14px;
  font-weight:bold;
  color:orangered!important
}
.parpadea {
  
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo{  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
   100% { opacity: 1.0; }
}

@keyframes parpadeo {  
  0% { opacity: 1.0; }
   50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
/* .listaAsesores {
  list-style-type: none;
  padding: 0;
}

.listaAsesores-item {
  margin-bottom: 10px;
} */
</style>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import controlesServices from '../../services/controles'
import { faPlay, faPause, faCog, faMoon, faArrowAltCircleUp, faArrowAltCircleDown, faEdit, faSave,faSmile, faAngry,faLaugh,faFrown,faMeh,faHeadSideMask,faKissBeam,faSadCry,faDizzy,faTired } from '@fortawesome/free-solid-svg-icons'
library.add(faPlay, faPause, faCog, faMoon, faArrowAltCircleUp, faArrowAltCircleDown, faEdit, faSave,faSmile, faAngry,faLaugh,faFrown,faMeh,faHeadSideMask,faKissBeam,faSadCry,faDizzy,faTired,faSadCry)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import asesorServices from '../../services/asesor'
import InputSwitch from 'primevue/inputswitch'
import SocketIo from 'socket.io-client'
import Sidebar from 'primevue/sidebar'
export default {
    name: 'ConfiAsesor',
    data() {
        return {
            asesores: null,
            esperar: false,
            switch: false,
            checked:false,
            panelLateral:false,
            panelGeneral:false,
            cola:null,
            nicaragua:{},
            salvador:{},
            tab:1,
            pedircola:null,
            sesion: JSON.parse(sessionStorage.getItem('sesion')),
            hemodialisis:null,
            seleccionado:{ "id": 0, "nombre": "Aqui aparece el nombre del paciente", "ATENDIENDO": "Numero de atendidos", "ESPERA_1": "Px en Pendientes", "sesion": true, "facebook": 0, "num_pacientes": 35, "cola": "DESC" },
            semana:['Domingo','Lunes','Martes','Miercoles','Jueves','Viernes','Sabado'],
            diaSemana:'',
            seleccionadoGen:{"num_pacientes": 10},
            listaAse:null,
            petct:null,
            google:null,
            gml:null
        }
    },
    components:{
        FontAwesomeIcon,
        InputSwitch,
        Sidebar,
    },
    computed: {
        seleccionadoEsp() {
            return this.listaAse
            .filter(list => list.seleccionado)
            .map(list => list.id);
        }
    }/* ,
    watch: {
        listaAse: {
        deep: true,
            handler() {
                // Eliminamos los IDs deseleccionados de la lista final
                this.listaAse = this.listaAse.filter(list => list.seleccionado);
            }
        }
    } */,
    created(){
        let data = {
            query: {
                "Tipo": "3"
            },
            /* transports: ["websocket", "polling"] */
        }
        let dataCola = {
            query: {
                "Tipo": "1"
            },
        
            /* transports: ["websocket", "polling"] */
        }
        let dataSV = {
                    query: {
                        "Tipo": "2"
                    }
                }
        const numeroDia = new Date().getDay();
        this.diaSemana = this.semana[numeroDia]

        const infoUsuario = this.sesion;

        if(infoUsuario.rol_id == 1 || infoUsuario.rol_id == 2){     
            var socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
            socket.on('parametros',  (datos) => {
                var moment = require("moment")
                require("moment-timezone")
                moment.locale("es")
                datos.map(x =>{
                    x.lastconnection = moment(x.lastconnection)
                    x.fin = moment(x.fin_sesion, 'YYYY-MM-DD HH:mm').calendar()
                    // x.primerMensaje.entrada1= x.primerMensaje.entrada1 !=="" ? moment(x.primerMensaje.entrada1).tz("America/Mazatlan").format("hh:mm:ss A") :""
                    // x.primerMensaje.entrada2= x.primerMensaje.entrada2 !=="" ? moment(x.primerMensaje.entrada2).tz("America/Mazatlan").format("hh:mm:ss A") :""
                    x.max_tiempo=moment(x.max_tiempo).tz("America/Mazatlan").calendar()
                    
                    if(x.break.activo==1){
                        x.break.desde = new Date(x.break.desde.slice(0,19));
                        x.break.desde.setHours(x.break.desde.getHours());
                        x.break.desde = moment(x.break.desde).tz("America/Mazatlan").fromNow();
                    }
                    
                    if((x.lastconnection.diff(moment(), 'days')==0)&&(x.fin_sesion==null)){
                        x.online=true
                    }else{
                        x.online=false
                    }
                    if(this.sesion.rol_id==1){
                        return x

                    } else{
                        return x.rol_id==3
                    }
                })
                datos.sort((a,b) =>{
                    return b.id - a.id
                })
                datos.sort((a,b) =>{
                    return (a.online === b.online)? 0 : a.online? -1 : 1
                })
                datos.sort((a,b) =>{
                    return b.ATENDIENDO - a.ATENDIENDO
                })
                this.asesores = this.sesion.grupo_id==6?datos.filter(x => x.grupo_id==6):datos.filter(x => x.grupo_id!=6)
            })
            var socketCola = SocketIo.connect('https://socket-ws.salud-digna.site', dataCola)
            socketCola.on('pedirColaRedes',  (payload) => {
                this.cola=payload
            })
            socketCola.on('pedirColaHemodialisis',  (payload) => {
                this.hemodialisis=payload
            })
            
            socketCola.on('pedirColaInbox',  (payload) => {
                this.pedircola=payload
            })
            /*socketCola.on('pedirColaG',  (payload) => {
                this.google=payload.cola
            })*/
            let colaNicaragua = {
                query: {
                    "Tipo": "2"
                }
            }
            var socketNicaragua = SocketIo.connect('https://socket-ws.salud-digna.site', colaNicaragua)
            socketNicaragua.on('pedirColaNicaragua',  (payload) => {
                this.nicaragua=payload
            })
            var socketSA = SocketIo.connect('https://socket-ws.salud-digna.site', dataSV)
            socketSA.on('pedirColaSalvador',  (payload) => {
                this.salvador=payload
            })
            var socketPetCt = SocketIo.connect('https://socket-ws.salud-digna.site', dataSV)
            socketPetCt.on('pedirColaPetCt',  (payload) => {
                this.petct=payload
            })

            var socketGml = SocketIo.connect('https://socket-ws.salud-digna.site', dataSV)
            socketGml.on('pedirColaGuatemala',  (payload) => {
                this.gml=payload
            })

        }
    },
    mounted(){
        this.get()
        this.listaAsesores()
    },
    methods: {
        get(){
            // reportesServices.asesorSesion().then(datos => {
            //     var moment = require("moment")
            //     moment.locale("es")
            //     datos.map(x =>{
            //         x.lastconnection = moment(x.lastconnection)
            //         x.fin = moment(x.fin_sesion, 'YYYY-MM-DD HH:mm').calendar()
            //         if((x.lastconnection.diff(moment(), 'days')==0)&&(x.fin_sesion==null)){
            //             x.online=true
            //         }else{
            //             x.online=false
            //         }
            //         return x
            //     })
            //     this.asesores = datos
            // })
            controlesServices.Control().then(resp => {
                resp.map(x => {
                    if(x.id==1){
                        this.switch=x
                    } else{
                        return
                    }
                    return x
                })
                
                if(this.switch.status==0){
                    this.checked=false
                }else{
                    this.checked=true
                }
            })
        },
        cambiarEstatus(id, estatus){
            this.esperar=true
            asesorServices.estatus(id, estatus).then(() =>{
                this.get()
                this.esperar=false
            })
        },
        mandarCola(a){
            this.esperar=true
            asesorServices.traspasarPacientesParametros(a).then(()=>{
                    this.get()
                    this.esperar=false
                })
        },
        cambiarSwitch(){
            let variable
            variable = this.checked ? 0:1
            controlesServices.CambiarControl(1, variable).then(() => {
                this.get()
            })
        },
        editar(asesor){
            this.seleccionado=asesor
            this.panelLateral=!this.panelLateral
        },
         editarGeneral(){
            this.panelGeneral=!this.panelGeneral
        },
        guardar(a,b,c,d,e){
            this.esperar=true
            let datos= {
                asesor_id: a,
                sesion: b,
                facebook: c,
                num_pacientes: d,
                cola: e,
            }
            controlesServices.updateAsesorConfig(datos).then(res=>{
                if(res){
                    this.panelLateral=false
                    this.seleccionado={ "id": 0, "nombre": "Aqui aparece el nombre del paciente", "ATENDIENDO": "Numero de atendidos", "ESPERA_1": "Px en Pendientes", "sesion": true, "facebook": 0, "num_pacientes": 35, "cola": "DESC" }
                }
                this.esperar=false
            })
        },
        guardarGen(a){
            this.esperar=true
            controlesServices.updateAsesorConfigGen(a).then(res=>{
                if(res){
                    this.panelGeneral=false
                    this.seleccionadoGen={"num_pacientes": 10}
                    controlesServices.getAsesorConfigGen()
                }
                this.esperar=false
            })
        },
        async cerrarBreak(asesor_id){
            const resp = await asesorServices.cerrarBreak(asesor_id)
            if(resp.response){
                const br = this.asesores.findIndex((e) => e.id == asesor_id)
                this.asesores[br].break.activo = 0
                this.asesores[br].sesion = true
            }
        },
        guardarGenEsp(data){
            this.esperar=true
            for (var i = 0; i < this.seleccionadoEsp.length; i++) {
                var elemento = this.seleccionadoEsp[i];
                var a = elemento;
                var d = data;

                var datos = {
                    asesor_id: a,
                    num_pacientes: d
                };
                controlesServices.updateAsesorConfigGenEsp(datos).then(res=>{
                if(res){
                    this.panelLateral=false
                }
                this.esperar=false
            })  
            }
            
        },
        listaAsesores() {
        asesorServices.getAsesorEsp().then(respa => {
        this.listaAse = respa.map(item => ({ ...item, seleccionado: true }));
      })
    }
    },
}
</script>
